import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Switch from "../images/about-switch.svg"
import Visibility from "../images/about-visibility.svg"
import Modular from "../images/about-modular.svg"
import Workspace from "../images/about-workspace.svg"
import Catalog from "../images/about-catalog.svg"

const Product = () => (
  <Layout>
    <SEO title="obvıous. platform" />
    <div className="relative pb-12">
      <div className="absolute z-0 md:z-10 inset-0 opacity-10 pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3724"
          height="609"
          viewBox="0 0 3724 609"
        >
          <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
            <g transform="translate(1209)">
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
            <g transform="matrix(1 0 0 -1 2482 609)">
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
            <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
            <circle cx="642" cy="158" r="20" fill="#00B1A9" />
            <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
            <circle cx="131" cy="250" r="34" fill="#00B1A9" />
            <circle cx="403" cy="562" r="19" fill="#00B1A9" />
            <circle cx="707" cy="253" r="35" fill="#00B1A9" />
            <circle cx="285" cy="199" r="43" fill="#00B1A9" />
            <circle cx="129" cy="74" r="28" fill="#75DDDD" />
            <circle cx="928" cy="256" r="35" fill="#508991" />
            <circle cx="477" cy="279" r="34" fill="#00B1A9" />
            <circle cx="57" cy="532" r="42" fill="#75DDDD" />
            <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
            <circle cx="172" cy="558" r="23" fill="#00B1A9" />
            <circle cx="20" cy="278" r="20" fill="#508991" />
            <circle cx="671" cy="550" r="26" fill="#508991" />
            <circle cx="367" cy="168" r="11" fill="#75DDDD" />
            <circle cx="840" cy="48" r="48" fill="#00B1A9" />
            <circle cx="261" cy="482" r="20" fill="#508991" />
            <circle cx="828" cy="50" r="29" fill="#508991" />
            <circle cx="891" cy="467" r="40" fill="#75DDDD" />
            <circle cx="442" cy="214" r="18" fill="#508991" />
            <circle cx="536" cy="264" r="29" fill="#508991" />
            <circle cx="104" cy="344" r="49" fill="#75DDDD" />
            <circle cx="703" cy="571" r="38" fill="#508991" />
            <circle cx="278" cy="313" r="33" fill="#00B1A9" />
            <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
            <circle cx="58" cy="374" r="50" fill="#75DDDD" />
            <circle cx="602" cy="465" r="42" fill="#00B1A9" />
            <circle cx="608" cy="507" r="29" fill="#508991" />
            <circle cx="555" cy="109" r="13" fill="#00B1A9" />
            <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
            <circle cx="221" cy="413" r="36" fill="#00B1A9" />
            <circle cx="1042" cy="165" r="18" fill="#508991" />
            <circle cx="666" cy="279" r="30" fill="#75DDDD" />
            <circle cx="1063" cy="100" r="26" fill="#508991" />
            <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
            <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
            <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
            <circle cx="287" cy="560" r="12" fill="#508991" />
            <circle cx="119" cy="238" r="24" fill="#75DDDD" />
            <circle cx="328" cy="571" r="14" fill="#508991" />
            <circle cx="888" cy="456" r="38" fill="#508991" />
            <circle cx="906" cy="73" r="16" fill="#00B1A9" />
            <circle cx="799" cy="257" r="41" fill="#00B1A9" />
            <circle cx="965" cy="367" r="11" fill="#75DDDD" />
            <circle cx="504" cy="50" r="12" fill="#75DDDD" />
            <circle cx="891" cy="379" r="21" fill="#75DDDD" />
            <circle cx="629" cy="218" r="11" fill="#508991" />
            <circle cx="757" cy="500" r="40" fill="#75DDDD" />
          </g>
        </svg>
      </div>
      <div className="relative pt-64 max-w-3xl text-center mx-auto">
        <h1 className="font-semibold text-2xl md:text-4xl text-white">
          Manage all your products in{" "}
          <span className="text-brand-grey-dark">one place.</span>
        </h1>
        <p className="font-medium text-base md:text-lg mt-6 leading-normal text-brand-grey-dark max-w-2xl mx-auto">
          Obvıous maximizes your product’s lifetime with a streamlined device
          management platform and the ability to monetize firmware features.
        </p>
      </div>
    </div>
    <div className="relative flex flex-col">
      <div className="w-full bg-brand-teal z-0">
        <svg
          viewBox="0 0 50 15"
          preserveAspectRatio="none"
          className="w-full bottom-0 h-32 md:h-76"
          style={{ marginBottom: "-1px" }}
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M0,14.9954241 C19.9497878,5.17408693 35.0012698,0.175684169 49.965204,0 C49.9695893,5.37969228 50.0383731,14.9954241 49.9695893,14.9954241 C38.8635973,14.9954241 22.2070676,14.9954241 0,14.9954241 Z"
              fill="#E8FFFE"
              fill-rule="nonzero"
            ></path>
          </g>
        </svg>
      </div>
      <div className="h-full w-full bg-brand-teal-light">
        <section className="obvs-features">
          <ul className="max-w-6xl mx-auto relative px-4 md:px-0 pricing relative z-10 overflow-auto -mt-32">
            <li class="switch">
              <div>
                <img src={Switch} alt="obvous. feature switches" />
              </div>
              <div>
                <dl>
                  <dt>
                    <span>A master switch for</span> feature management.
                  </dt>
                  <dd>
                    Decide which features you want available for your devices.
                    At the flip of a switch, obvıous allows after-sale features
                    to be purchased or enabled by default.
                  </dd>
                </dl>
              </div>
            </li>

            <li class="visibility">
              <div>
                <img
                  src={Visibility}
                  alt="obvous. device visibiltiy and settings"
                />
              </div>
              <div>
                <dl>
                  <dt>
                    <span>Full visibility across all</span> devices.
                  </dt>
                  <dd>
                    To check which features are activated on a particular
                    device, or change the status of a certain feature, simply
                    select the device by its serial number and obtain immediate
                    insight.
                  </dd>
                </dl>
              </div>
            </li>

            <li class="modular">
              <div>
                <img src={Modular} alt="obvous. device firmare and groups" />
              </div>
              <div>
                <dl>
                  <dt>
                    Manage your <span>release processes.</span>
                  </dt>
                  <dd>
                    Ensure you don’t accidentally release development firmware
                    to live products. With groups, we help you clearly
                    distinguish between the firmware versions associated with
                    each of your devices.
                  </dd>
                </dl>
              </div>
            </li>

            <li class="workspace">
              <div>
                <img src={Workspace} alt="obvous. firmware workspace" />
              </div>
              <div>
                <dl>
                  <dt>
                    Declutter <span>your workspace.</span>
                  </dt>
                  <dd>
                    Every firmware version added to obvıous is backed up to our
                    secure server, along with your release notes and
                    dependencies, allowing your team to spend less time managing
                    files and more time adding value to your product.
                  </dd>
                </dl>
              </div>
            </li>

            <li class="catalogue">
              <div>
                <img src={Catalog} alt="obvous catalog item" />
              </div>
              <div>
                <dl>
                  <dt>
                    <span>Create your</span> catalogue.
                  </dt>
                  <dt></dt>
                  <dd>
                    Whether you wish to charge for features individually or
                    packaged together, as a one-time payment or a subscription,
                    obvıous gives you the freedom to decide how you want to
                    offer additional functionality to your customers.
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </section>
        <section className="bg-brand-teal">
          <svg
            version="1.1"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="h-24 w-full flip"
          >
            <g fill="#E8FFFE">
              <path d="M0 100 C40 0 60 0 100 100 Z"></path>
            </g>
          </svg>
          <div className="bg-brand-teal py-16 ">
            <div className="md:max-w-4xl w-full mx-auto flex flex-wrap items-center justify-between px-6 md:px-6">
              <div className="w-full md:w-1/2">
                <div className="text-3xl text-brand-teal-light font-medium text-center md:text-left">
                  Ready to get started?
                </div>
                <div className="text-xl text-brand-grey-dark font-semibold mt-1 text-center md:text-left">
                  Get in touch or create an account.
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-end md:ml-16 mt-8 md:mt-0 w-full md:w-auto">
                <div className="md:mr-4 w-full md:w-auto">
                  <a
                    href="https://portal.obvious.xyz/create"
                    target="_blank"
                    className="text-center block ml-0 mt-2 md:mt-0 md:ml-2 w-full md:w-auto px-6 py-3 text-brand-teal-dark font-semibold bg-brand-teal-light rounded-lg no-underline transition-1/4"
                  >
                    Get started
                  </a>
                </div>
                <div className="mt-2 md:mt-0 w-full md:w-auto">
                  <Link
                    to="/contact"
                    className=" text-center block ml-0 mt-2 md:mt-0 md:ml-2 w-full md:w-auto px-6 py-3 text-brand-teal-light font-semibbold border-brand-teal-light border-2 rounded-lg no-underline transition-1/4"
                  >
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-brand-teal -mb-1">
        <svg
          version="1.1"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className="h-24 w-full"
        >
          <g fill="#101821">
            <path d="M0 100 C40 0 60 0 100 100 Z"></path>
          </g>
        </svg>
      </div>
    </div>
  </Layout>
)

export default Product
